<template>
  <Card insideClass="max-w-max">
    <div v-if="user.ok === false" class="mb-4 text-2xl font-bold">Es gibt Problemen bei die Verbindung zum Server.</div>
    <div v-else class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
      <table class="divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <TableHeader>Leadthema</TableHeader>
          <TableHeader>Importiert durch</TableHeader>
          <TableHeader>Anzahl</TableHeader>
        </thead>
        <tbody v-for="single in history">
          <tr class="text-left">
            <td class="px-6 py-1.5">
              <a
                :href="
                  'https://crmz.org/index.php?module=Leads&page=1&view=List&viewname=4895&search_params=[[[%22cf_751%22%2C%22c%22%2C%22' +
                  encodeURIComponent(single.leadtheme) +
                  '%22]]]'
                "
                target="_blank"
                class="font-semibold underline hover:text-blue-800"
                >{{ single.leadtheme }}</a
              >
            </td>
            <td class="px-6 py-1.5">{{ single.imported_by }}</td>
            <td class="px-6 py-1.5">{{ single.numberofleads }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/html/Card.vue';
import useCadis from '@/composables/useCadis';
import TableHeader from '@/components/html/TableHeader.vue';
import router from '@/router';
import useMsal from '@/composables/useMsal';
export default {
  components: { Card, TableHeader },
  setup() {
    const { history, user, checkUser } = useCadis();
    checkUser();

    const { account } = useMsal();
    if (!account.value) router.push('/');

    return { history, user };
  },
};
</script>
